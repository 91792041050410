<template>
  <div class="article-catalog">
    <el-container>
      <el-header class="head-search">
        <el-row :gutter="24" style="width: 100%; text-align: right">
          <el-col :span="4">
            <el-input
              maxlength="32"
              show-word-limit
              clearable
              placeholder="请输入关键字进行搜索"
              v-model="filterText"
              size="large"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="openOption" clearable placeholder="请选择目录层级" size="large">
              <el-option
                v-for="index in 5"
                :key="index"
                :label="changeNum[index - 1] + '级 目录'"
                :value="index"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="16"></el-col>
        </el-row>
      </el-header>
      <el-main style="background-color: #ffffff; padding: 0 20px">
        <el-tree
          ref="tree"
          :data="data"
          :props="props"
          :indent="30"
          draggable
          default-expand-all
          @node-drop="handleDrop"
          :filter-node-method="filterNode"
          @node-contextmenu="rightClick"
          style="width: 100%; height: 100%"
        >
          <template #default="{ node, data }">
            <span class="tree-node">
              <span>
                <icon
                  :icon-name="data.catalogType === 'FILE' ? 'FolderOpened' : 'Document'"
                  :icon-size="15"
                  :icon-title="' ' + node.label"
                ></icon>
              </span>
              <el-button
                type="text"
                v-if="data.catalogType === 'TITLE'"
                @click="queryDetail(data.serial)"
              >
                详 情
                <el-icon class="el-icon--right">
                  <component :is="DArrowRight"></component>
                </el-icon>
              </el-button>
            </span>
          </template>
        </el-tree>
        <div id="menu" v-show="menuVisible" @mouseleave="menuVisible = !menuVisible">
          <el-card class="box-card">
            <div>
              <el-button type="text" style="width: 100%; text-align: left" @click="addNode">
                <icon icon-name="Plus" icon-size="20" icon-title="添 加"></icon>
              </el-button>
            </div>
            <div>
              <el-button type="text" style="width: 100%; text-align: left" @click="renameNode">
                <icon icon-name="Edit" icon-size="20" icon-title="命名"></icon>
              </el-button>
            </div>
            <div>
              <el-button type="text" style="width: 100%; text-align: left" @click="deleteNode">
                <icon icon-name="Delete" icon-size="20" icon-title="删 除"></icon>
              </el-button>
            </div>
          </el-card>
        </div>
      </el-main>
      <el-footer></el-footer>
    </el-container>
  </div>
</template>

<script>
import { DArrowRight } from '@element-plus/icons-vue'
import icon from '@/components/icon'
import {
  catalogTreeFile,
  createCatalog,
  deleteCatalog,
  moveCatalog,
  updateCatalogTitle
} from '@/api/ArticleApi'

export default {
  name: 'ArticleCatalog',
  components: {
    icon
  },
  setup() {
    return {
      DArrowRight
    }
  },
  data() {
    return {
      filterText: '',
      menuVisible: false,
      data: [],
      props: {
        value: 'serial',
        label: 'title',
        children: 'children'
      },
      right: {
        serial: '',
        parent: '',
        title: ''
      },
      changeNum: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      openOption: ''
    }
  },
  methods: {
    // 过滤
    filterNode(value, data) {
      if (!value) return true
      return data.title.indexOf(value) !== -1
    },
    // 鼠标右键方法
    rightClick(MouseEvent, object) {
      if (object.catalogType === 'TITLE') {
        return
      }
      this.menuVisible = true
      this.right.serial = object.serial
      this.right.parent = object.parent
      this.right.title = object.title
      const menu = document.querySelector('#menu')
      menu.style.cssText =
        'position: fixed; left: ' +
        (MouseEvent.clientX - 10) +
        'px' +
        '; top: ' +
        (MouseEvent.clientY - 10) +
        'px; z-index: 999; cursor:pointer;'
    },
    // 添加目录
    addNode() {
      this.$prompt('请输入目录名称', '添加目录', {
        confirmButtonText: '创 建',
        cancelButtonText: '取 消',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        inputPattern: /^.{1,32}$/,
        inputErrorMessage: '目录名称1~32字！'
      }).then(({ value }) => {
        createCatalog({
          title: value,
          parent: this.right.serial
        }).then((res) => {
          this.clearRight()
          if (res.code === 200) {
            this.$message({ type: 'success', message: '添加成功' })
            this.queryCatalog()
          } else {
            this.$message({ type: 'error', message: res.msg })
          }
        })
      })
    },
    // 删除目录
    deleteNode() {
      this.$confirm('此操作将永久删除该文件夹, 是否继续?', '提示', {
        confirmButtonText: '删 除',
        cancelButtonText: '取 消',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning'
      }).then(() => {
        deleteCatalog({
          serial: this.right.serial
        }).then((res) => {
          this.clearRight()
          if (res.code === 200) {
            this.$message({ type: 'success', message: '删除成功' })
            this.queryCatalog()
          } else {
            this.$message({ type: 'error', message: res.msg })
          }
        })
      })
    },
    // 重新命名
    renameNode() {
      this.$prompt('请输入目录名称', '添加目录', {
        confirmButtonText: '修 改',
        cancelButtonText: '取 消',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        inputPattern: /^.{1,32}$/,
        inputErrorMessage: '目录名称1~32字！',
        inputValue: this.right.title
      }).then(({ value }) => {
        updateCatalogTitle({
          title: value,
          serial: this.right.serial
        }).then((res) => {
          this.clearRight()
          if (res.code === 200) {
            this.$message({ type: 'success', message: '修改成功' })
            this.queryCatalog()
          } else {
            this.$message({ type: 'error', message: res.msg })
          }
        })
      })
    },
    // 拖拽成功完成时触发的事件
    handleDrop(draggingNode, dropNode, dropType) {
      const target = draggingNode.data.serial
      const catalogType = draggingNode.data.catalogType
      const parent = dropType === 'inner' ? dropNode.data.serial : dropNode.data.parent
      moveCatalog({
        current: target,
        moveTo: parent,
        type: catalogType
      }).then((res) => {
        if (res.code === 200) {
          this.$message({ type: 'success', message: '移动成功', duration: 1000 })
          this.queryCatalog()
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      })
    },
    // 清除右键选择
    clearRight() {
      this.right.serial = ''
      this.right.parent = ''
      this.right.title = ''
    },
    // 查询目录树
    queryCatalog() {
      catalogTreeFile().then((res) => {
        if (res.code === 200) {
          this.data = res.data
        }
      })
    },
    spreadTree(lv) {
      const nodesMap = this.$refs.tree.store.nodesMap
      for (const key in nodesMap) {
        if (nodesMap[key].level === lv) {
          nodesMap[key].expanded = false
          nodesMap[key].isCurrent = false
        } else {
          nodesMap[key].expanded = true
          nodesMap[key].isCurrent = false
        }
      }
    },
    queryDetail(serial) {
      this.$router.push({ name: 'PreviewNotes', query: { serial: serial } })
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    openOption(val) {
      this.spreadTree(val)
    }
  },
  mounted() {
    this.queryCatalog()
  }
}
</script>

<style scoped>
.article-catalog {
  width: 100%;
  height: 93%;
  min-width: 700px;
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
}

.head-search {
  height: 80px;
}

.article-catalog >>> .el-header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.box-card div {
  height: 30px;
}

.article-catalog .tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
